.post-card {

    font-family: 'KhmerOSBattambang', sans-serif;
    font-size: medium;
    /* background-color: #fff; */
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    height: auto;
    /* border-bottom: 2px solid #07e74a; */
}

.post-card:hover {
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.post-image {

    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 2px;
    margin-bottom: 5px;
    height: 200px;
    border-radius: 5px;
}

.post-card h3 {
    margin: 0 0 8px;
    font-size: 1.2em;
    color: #333;
}

.post-card p {
    font-weight: bold;
    line-height: 1.3;
    margin: 0 0 8px;
    font-size: 1em;
    color: #08779c;
}

.post-card small {
    display: block;
    font-size: 0.8em;
    color: #999;
    text-align: right;
}

.post-header {

    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.post-avatar img {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #0ba1b9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.post-user p {
    background-color: #bebebe;
    padding-left: 2px;
    border-radius: 2px;
    color: #fff;
    margin: 0;
    font-weight: bold;
    font-size: small;
}

.card-likecommat {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}