.post-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .post-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 480px) {
    .post-list {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
.sprinSave-container {
    position: relative;
    max-width: 400px;
    /* Adjust the value based on your design */
    margin: 0 auto;
    /* Center the container horizontally */
}

.sprinSave {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgba(249, 249, 249, 0.7); */
    width: 100%;
    height: 100%;
    z-index: 1;
    /* Ensure the spinner is above other content within the container */
}
/* MyComponent.css */
.custom-pagination .ant-pagination-item {
    border-color: rgb(105, 105, 110) !important;
    font-weight: bold !important;
    font-size: 18px;
    border-radius: 50%;
    padding: 5px;
    justify-items: center;
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
}

.custom-pagination .ant-pagination-item a {
    color: rgb(93, 90, 90) !important;
}

.custom-pagination .ant-pagination-item-active {

    border-color: rgb(48, 49, 48) !important;
    color: white !important;

    background-color: rgb(6, 155, 182) !important;
}

.custom-pagination .ant-pagination-item-active a {
    color: white !important;

}