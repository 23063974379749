.home-style {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .home-style {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 480px) {
    .home-style {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}